var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "clist" }, [
    _c(
      "ul",
      _vm._l(_vm.nav, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.select === item.select ? "is-select" : "",
            attrs: { type: "text" },
            on: {
              click: function ($event) {
                return _vm.changeItem(item)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(item.name) + " "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.select === item.select,
                  expression: "select === item.select",
                },
              ],
            }),
          ]
        )
      }),
      0
    ),
    _vm.select === 1
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _vm.total.item_total > 0
              ? _c("p", { staticClass: "total-cont" }, [
                  _vm._v(" 订单数："),
                  _c("span", [_vm._v(_vm._s(_vm.total.item_total))]),
                  _vm._v("； 实付总金额："),
                  _c("span", [_vm._v(_vm._s(_vm.total.actual_price_fee))]),
                  _vm._v("； 实付总金额手续费："),
                  _c("span", [_vm._v(_vm._s(_vm.total.actual_price_fee_rate))]),
                  _vm._v("； 平台补贴总金额："),
                  _c("span", [
                    _vm._v(_vm._s(_vm.total.actual_coupon_price_fee)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.list,
                  "element-loading-text": "拼命加载中",
                  "header-row-style": { background: "#d9dde1" },
                  "header-cell-style": {
                    background: "#d9dde1",
                    color: "#0D0202",
                    fontSize: "12px",
                  },
                },
              },
              [
                _c("el-table-column", { attrs: { prop: "id", label: "序号" } }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchase_sn",
                    label:
                      _vm.$route.path === "/closeoperate"
                        ? "出货单"
                        : "销售单号",
                    width: "220",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "order_sn", label: "订单号", width: "220" },
                }),
                _c("el-table-column", {
                  attrs: { width: "120", label: "结算标记" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.is_settlement === 0
                                    ? "未结算"
                                    : "已结算"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    352856357
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_name",
                    label: "商品名称",
                    width: "250",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "spec_key_name",
                    label: "规格名称",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_num", label: "数量" },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    prop: "goods_price",
                    label: "销售单价",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    prop: "goods_price_amount",
                    label: "销售总价",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "actual_price_fee_rate", label: "支付手续费" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "actual_coupon_price_suppliers_fee",
                    label: "商家优惠金额",
                    width: "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "actual_coupon_price_fee",
                    label: "平台补贴金额",
                    width: "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "actual_price_fee", label: "实付金额" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "create_at", label: "创建时间", width: "190" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.select === 2
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _vm.total.item_total > 0
              ? _c("p", { staticClass: "total-cont" }, [
                  _vm._v(" 售后订单数："),
                  _c("span", [_vm._v(_vm._s(_vm.total.item_total))]),
                  _vm._v("； 售后总金额："),
                  _c("span", [_vm._v(_vm._s(_vm.total.shop_price))]),
                  _vm._v("； 售后总金额手续费："),
                  _c("span", [_vm._v(_vm._s(_vm.total.shop_price_rate))]),
                  _vm._v("； 优惠券退款总金额："),
                  _c("span", [
                    _vm._v(_vm._s(_vm.total.actual_coupon_price_fee)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.list,
                  "header-row-style": { background: "#d9dde1" },
                  "header-cell-style": {
                    background: "#d9dde1",
                    color: "#0D0202",
                    fontSize: "12px",
                  },
                  "element-loading-text": "拼命加载中",
                },
              },
              [
                _c("el-table-column", { attrs: { prop: "id", label: "序号" } }),
                _c(
                  "el-table-column",
                  { attrs: { label: "售后单号", width: "200" } },
                  [[_vm._v("暂无")]],
                  2
                ),
                _c("el-table-column", {
                  attrs: { prop: "order_sn", label: "订单号", width: "210" },
                }),
                _c("el-table-column", {
                  attrs: { label: "状态" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.is_settlement === 0
                                    ? "未结算"
                                    : "已结算"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    352856357
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_name",
                    label: "商品名称",
                    width: "240",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "spec_key_name",
                    label: "规格名称",
                    width: "165",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "num", label: "数量" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "退款金额",
                    prop: "shop_price",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "退款方式", prop: "pay_name", width: "180" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "退款金额手续费",
                    prop: "shop_price_rate",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "优惠券退款",
                    prop: "actual_coupon_price_fee",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "create_at", label: "创建时间", width: "180" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "comment", label: "备注", width: "150" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.select === 3
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _vm.total.item_total > 0
              ? _c("p", { staticClass: "total-cont" }, [
                  _vm._v(" 扣款订单数："),
                  _c("span", [_vm._v(_vm._s(_vm.total.item_total))]),
                  _vm._v("； 扣款总金额："),
                  _c("span", [_vm._v(_vm._s(_vm.total.deduc_amount))]),
                ])
              : _vm._e(),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.list,
                  "header-row-style": { background: "#d9dde1" },
                  "header-cell-style": {
                    background: "#d9dde1",
                    color: "#0D0202",
                    fontSize: "12px",
                  },
                  "element-loading-text": "拼命加载中",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", width: "120", label: "序号" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchase_sn",
                    label: "关联销售单号",
                    width: "200",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goods_name",
                    label: "商品名称",
                    width: "240",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "spec_key_name",
                    label: "规格名称",
                    width: "165",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goods_num", label: "数量" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "actual_goods_price",
                    label: "销售金额",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "money", label: "扣款金额", width: "180" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "ctime", label: "创建时间", width: "150" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "reason_detail", label: "备注", width: "150" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }