<template>
  <div class="clist">
    <ul>
      <div
        v-for="(item,index) in nav"
        :key="index"
        type="text"
        :class="select === item.select ? 'is-select' : ''"
        @click="changeItem(item)"
      >
        {{ item.name }}
        <span v-show="select === item.select" />
      </div>
    </ul>
    <!-- 采购单 -->
    <div
      v-if="select === 1"
      class="list"
    >
      <p
        v-if="total.item_total > 0"
        class="total-cont"
      >
        订单数：<span>{{ total.item_total }}</span>；
        实付总金额：<span>{{ total.actual_price_fee }}</span>；
        实付总金额手续费：<span>{{ total.actual_price_fee_rate }}</span>；
        平台补贴总金额：<span>{{ total.actual_coupon_price_fee }}</span>
      </p>
      <el-table
        v-loading="loading"
        :data="list"
        element-loading-text="拼命加载中"
        style="width: 100%"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
      >
        <el-table-column
          prop="id"
          label="序号"
        />
        <el-table-column
          prop="purchase_sn"
          :label="$route.path === '/closeoperate' ? '出货单' : '销售单号'"
          width="220"
        />
        <el-table-column
          prop="order_sn"
          label="订单号"
          width="220"
        />
        <el-table-column
          width="120"
          label="结算标记"
        >
          <template slot-scope="scope">
            {{ scope.row.is_settlement === 0 ? '未结算' : '已结算' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品名称"
          width="250"
        />
        <el-table-column
          prop="spec_key_name"
          label="规格名称"
          width="180"
        />
        <el-table-column
          prop="goods_num"
          label="数量"
        />
        <el-table-column
          width="100"
          prop="goods_price"
          label="销售单价"
        />
        <el-table-column
          width="120"
          prop="goods_price_amount"
          label="销售总价"
        />
        <el-table-column
          prop="actual_price_fee_rate"
          label="支付手续费"
        />
        <el-table-column
          prop="actual_coupon_price_suppliers_fee"
          label="商家优惠金额"
          width="120"
        />
        <el-table-column
          prop="actual_coupon_price_fee"
          label="平台补贴金额"
          width="120"
        />
        <el-table-column
          prop="actual_price_fee"
          label="实付金额"
        />
        <el-table-column
          prop="create_at"
          label="创建时间"
          width="190"
        />
      </el-table>
    </div>
    <!-- 售后单 -->
    <div
      v-if="select === 2"
      class="list"
    >
      <p
        v-if="total.item_total > 0"
        class="total-cont"
      >
        售后订单数：<span>{{ total.item_total }}</span>；
        售后总金额：<span>{{ total.shop_price }}</span>；
        售后总金额手续费：<span>{{ total.shop_price_rate }}</span>；
        优惠券退款总金额：<span>{{ total.actual_coupon_price_fee }}</span>
      </p>
      <el-table
        v-loading="loading"
        :data="list"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="序号"
        />
        <el-table-column
          label="售后单号"
          width="200"
        >
          <template>暂无</template>
        </el-table-column>
        <el-table-column
          prop="order_sn"
          label="订单号"
          width="210"
        />
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.is_settlement === 0 ? '未结算' : '已结算' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品名称"
          width="240"
        />
        <el-table-column
          prop="spec_key_name"
          label="规格名称"
          width="165"
        />
        <el-table-column
          prop="num"
          label="数量"
        />
        <el-table-column
          label="退款金额"
          prop="shop_price"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="退款方式"
          prop="pay_name"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="退款金额手续费"
          prop="shop_price_rate"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="优惠券退款"
          prop="actual_coupon_price_fee"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="create_at"
          label="创建时间"
          width="180"
        />
        <el-table-column
          prop="comment"
          label="备注"
          width="150"
        />
      </el-table>
    </div>
    <!-- 扣款单 -->
    <div
      v-if="select === 3"
      class="list"
    >
      <p
        v-if="total.item_total > 0"
        class="total-cont"
      >
        扣款订单数：<span>{{ total.item_total }}</span>；
        扣款总金额：<span>{{ total.deduc_amount }}</span>
      </p>
      <el-table
        v-loading="loading"
        :data="list"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          width="120"
          label="序号"
        />
        <el-table-column
          prop="purchase_sn"
          label="关联销售单号"
          width="200"
        />
        <el-table-column
          prop="goods_name"
          label="商品名称"
          width="240"
        />
        <el-table-column
          prop="spec_key_name"
          label="规格名称"
          width="165"
        />
        <el-table-column
          prop="goods_num"
          label="数量"
        />
        <el-table-column
          prop="actual_goods_price"
          label="销售金额"
          width="180"
        />
        <el-table-column
          prop="money"
          label="扣款金额"
          width="180"
        />
        <el-table-column
          prop="ctime"
          label="创建时间"
          width="150"
        />
        <el-table-column
          prop="reason_detail"
          label="备注"
          width="150"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Clist',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Number,
      default: 1
    },
    total: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isRead: 1, // 权限
      nav: [{ name: '销售单', select: 1 }, { name: '售后单', select: 2 }, { name: '扣款单', select: 3 }],
      select: 1
    }
  },
  mounted() {
    this.isRead = Number(sessionStorage.getItem('is_read'))
  },
  methods: {
    changeItem(item) {
      this.select = item.select
      console.log(this.select, 'select')
      this.$emit('selectIndex', item.select)
    }
  }
}
</script>

<style scoped lang="scss">
  .clist {
    margin: 20px 0;
  }
  .clist ul {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .clist ul div {
    margin: 0 30px 0 0;
    color: #C5C8CA;
    cursor: pointer;
    height: 45px;
    line-height: 45px;
    position: relative;
    font-size: 16px;
    span {
      width: 19px;
      height: 2px;
      background: #20A0FF;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .clist ul .is-select {
    color: #20A0FF;
    font-weight: 600;
  }
  .clist .list {
    margin: 15px 0;
  }
  .total-cont {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
</style>
