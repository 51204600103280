var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c("div", { staticClass: "model-title" }, [_vm._v(" 结算单信息 ")]),
        _c("DetailContent", [
          _c("div", [
            _c("span", [_vm._v("结算单号：")]),
            _vm._v(_vm._s(_vm.info.info.settlement_sn) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("结算类型：")]),
            _vm._v(_vm._s(_vm.info.info.type_name) + " "),
          ]),
          _c("div", [
            _c("span", { staticClass: "danger" }, [_vm._v("结算状态：")]),
            _vm._v(_vm._s(_vm.info.info.status_name) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("结算范围：")]),
            _vm._v(_vm._s(_vm.info.info.range) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("结算金额：")]),
            _vm._v(_vm._s(_vm.info.info.settlement_price) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("实付金额：")]),
            _vm._v(_vm._s(_vm.info.info.price_fee) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("实付金额手续费：")]),
            _vm._v(_vm._s(_vm.info.info.price_fee_rate) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("平台补贴金额：")]),
            _vm._v(_vm._s(_vm.info.info.coupon_fee) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("售后总金额：")]),
            _vm._v(_vm._s(_vm.info.info.sale_price) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("售后总金额手续费：")]),
            _vm._v(_vm._s(_vm.info.info.sale_price_rate) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("扣款总金额：")]),
            _vm._v(_vm._s(_vm.info.info.deduc_price) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("汇款金额：")]),
            _vm._v(_vm._s(_vm.info.info.remittance_price) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("结算理由：")]),
            _c("i", [_vm._v(_vm._s(_vm.info.info.reason))]),
          ]),
          _c("div", [
            _c("span", [_vm._v("差异金额：")]),
            _vm._v(_vm._s(_vm.info.info.disputed_price) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("差异原因：")]),
            _vm._v(_vm._s(_vm.info.info.disputed_reason) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("最终结算金额：")]),
            _vm._v(_vm._s(_vm.info.info.settlement_price_last) + " "),
          ]),
        ]),
        _c("div", { staticClass: "model-title" }, [_vm._v(" 供应商信息 ")]),
        _c("DetailContent", [
          _c("div", [
            _c("span", [_vm._v("供应商名称：")]),
            _vm._v(_vm._s(_vm.info.suppliers.suppliers_name) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("联系人：")]),
            _vm._v(_vm._s(_vm.info.suppliers.suppliers_contacts) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("联系方式：")]),
            _vm._v(_vm._s(_vm.info.suppliers.phone) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("收款单位(人)：")]),
            _vm._v(_vm._s(_vm.info.suppliers.payee) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("收款开户银行：")]),
            _vm._v(_vm._s(_vm.info.suppliers.bank) + " "),
          ]),
          _c("div", [
            _c("span", [_vm._v("收款账号：")]),
            _vm._v(_vm._s(_vm.info.suppliers.bank_account) + " "),
          ]),
        ]),
        _c("div", { staticClass: "model-title" }, [_vm._v(" 查看单据明细 ")]),
        _c(
          "div",
          { staticStyle: { padding: "0 10px" } },
          [
            _c("c-list", {
              attrs: {
                list: _vm.tableData,
                total: _vm.totalData,
                loading: _vm.loading,
              },
              on: { selectIndex: _vm.changeType },
            }),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.pagination.currentPage,
                "page-sizes": [10, 20, 30, 50],
                "page-size": _vm.pagination.nowPageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pagination.count,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
        _vm.info.info.status === 6
          ? _c("div", { staticClass: "model-title" }, [_vm._v(" 结算操作 ")])
          : _vm._e(),
        _vm.info.info.status === 6 && _vm.isRead === 0
          ? _c(
              "p",
              { staticStyle: { "padding-left": "40px" } },
              [
                _c("span", [_vm._v("操作选项:")]),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 10px", width: "100px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.statusType = 3
                        _vm.confirmTip = true
                      },
                    },
                  },
                  [_vm._v(" 确 认 ")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 10px", width: "100px" },
                    on: {
                      click: function ($event) {
                        _vm.statusType = 8
                        _vm.confirmTip = true
                      },
                    },
                  },
                  [_vm._v(" 拒 绝 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-dialog",
          {
            attrs: { title: "提示", visible: _vm.confirmTip, size: "tiny" },
            on: {
              "update:visible": function ($event) {
                _vm.confirmTip = $event
              },
            },
          },
          [
            _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.statusType === 3
                      ? "您确定没问题吗？"
                      : "请输入您的问题："
                  ) +
                  " "
              ),
            ]),
            _vm.statusType !== 3
              ? _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "150",
                    placeholder: "请描述问题,最多150个字",
                  },
                  model: {
                    value: _vm.reason,
                    callback: function ($$v) {
                      _vm.reason = $$v
                    },
                    expression: "reason",
                  },
                })
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.confirmTip = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.changeStatus },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }