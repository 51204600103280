<template>
  <div class="app-container">
    <div class="outer">
      <!-- <DetailTitle title="结算单信息" /> -->
      <div class="model-title">
        结算单信息
      </div>
      <DetailContent>
        <div>
          <span>结算单号：</span>{{ info.info.settlement_sn }}
        </div>
        <div>
          <span>结算类型：</span>{{ info.info.type_name }}
        </div>
        <div>
          <span

            class="danger"
          >结算状态：</span>{{ info.info.status_name }}
        </div>
        <div>
          <span>结算范围：</span>{{ info.info.range }}
        </div>
        <div>
          <span>结算金额：</span>{{ info.info.settlement_price }}
        </div>
        <div>
          <span>实付金额：</span>{{ info.info.price_fee }}
        </div>
        <div>
          <span>实付金额手续费：</span>{{ info.info.price_fee_rate }}
        </div>
        <div>
          <span>平台补贴金额：</span>{{ info.info.coupon_fee }}
        </div>
        <div>
          <span>售后总金额：</span>{{ info.info.sale_price }}
        </div>
        <div>
          <span>售后总金额手续费：</span>{{ info.info.sale_price_rate }}
        </div>
        <div>
          <span>扣款总金额：</span>{{ info.info.deduc_price }}
        </div>
        <div>
          <span>汇款金额：</span>{{ info.info.remittance_price }}
        </div>
        <div>
          <span>结算理由：</span><i>{{ info.info.reason }}</i>
        </div>
        <div>
          <span>差异金额：</span>{{ info.info.disputed_price }}
        </div>
        <div>
          <span>差异原因：</span>{{ info.info.disputed_reason }}
        </div>
        <div>
          <span>最终结算金额：</span>{{ info.info.settlement_price_last }}
        </div>
      </DetailContent>
      <!-- <DetailTitle title="供应商信息" /> -->
      <div class="model-title">
        供应商信息
      </div>
      <DetailContent>
        <div>
          <span>供应商名称：</span>{{ info.suppliers.suppliers_name }}
        </div>
        <div>
          <span>联系人：</span>{{ info.suppliers.suppliers_contacts }}
        </div>
        <div>
          <span>联系方式：</span>{{ info.suppliers.phone }}
        </div>
        <div>
          <span>收款单位(人)：</span>{{ info.suppliers.payee }}
        </div>
        <div>
          <span>收款开户银行：</span>{{ info.suppliers.bank }}
        </div>
        <div>
          <span>收款账号：</span>{{ info.suppliers.bank_account }}
        </div>
      </DetailContent>
      <!-- <DetailTitle title="查看单据明细" />  -->
      <div class="model-title">
        查看单据明细
      </div>
      <div style="padding: 0 10px;">
        <c-list
          :list="tableData"
          :total="totalData"
          :loading="loading"
          @selectIndex="changeType"
        />
        <el-pagination
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.nowPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>

      <div
        v-if="info.info.status === 6"
        class="model-title"
      >
        结算操作
      </div>
      <p
        v-if="info.info.status === 6 && isRead === 0"
        style="padding-left: 40px;"
      >
        <span>操作选项:</span>
        <el-button
          style="margin:0 10px;width: 100px;"
          type="primary"
          @click="statusType=3;confirmTip=true"
        >
          确 认
        </el-button>
        <el-button
          style="margin:0 10px;width: 100px;"
          @click="statusType=8;confirmTip=true"
        >
          拒 绝
        </el-button>
      </p>
      <!-- <DetailTitle title="操作日志" /> -->
      <!-- <div class="model-title">
        操作日志
      </div>
      <el-table
        :data="info.log.data"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
        style="width:98%;margin:0 auto"
      >
        <el-table-column
          prop="user_name"
          label="操作者"
          width="180"
        />
        <el-table-column
          prop="status_name"
          label="结算状态"
          width="180"
        />
        <el-table-column
          prop="created_at"
          label="操作时间"
          width="230"
        />
        <el-table-column
          prop="remarks"
          label="操作内容"
        />
      </el-table>
    </div> -->
      <!-- 确定弹窗  -->
      <el-dialog
        title="提示"
        :visible.sync="confirmTip"
        size="tiny"
      >
        <p style="margin-bottom: 10px;">
          {{ statusType === 3 ? '您确定没问题吗？' : '请输入您的问题：' }}
        </p>
        <el-input
          v-if="statusType !== 3"
          v-model="reason"
          type="textarea"
          maxlength="150"
          placeholder="请描述问题,最多150个字"
        />
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            size="mini"
            @click="confirmTip = false"
          >取 消</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="changeStatus"
          >确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import cList from '@/components/finance/cList'
// import DetailTitle from '@/components/common/DetailTitle'
import DetailContent from '@/components/common/DetailContent'
export default {

  components: {
    cList,
    // DetailTitle,
    DetailContent
  },
  data() {
    return {
      isRead: 1, // 权限
      // 分页
      tableData: [],
      totalData: {},
      type: 1, // 1 采购单  2 售后单

      logList: [],
      info: {
        info: {},
        suppliers: {},
        log: {}
      },

      // 1=待审核 2=待财审 3=待结算 4=已结算 5=已退回
      statusType: '', // 目标状态值
      reason: '', // 拒绝理由
      confirmTip: false, // 确定弹窗
      count: 0,
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.isRead = Number(sessionStorage.getItem('is_read'))
    this.getDetail()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    getList() {
      let _this = this
      _this.loading = true
      let urls = _this.type === 1 ? _this.$api.closeOrder.get_purchase_list : _this.type === 2 ? _this.$api.closeOrder.get_return_list : _this.$api.closeOrder.get_deduction_list
      _this.$axios.get(urls, {
        params: {
          page: _this.pagination.currentPage,
          suppliers_id: _this.info.info.suppliers_id,
          is_settlement: 1,
          id: _this.$route.query.id
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          _this.tableData = _this.type === 3 ? r.data.data : r.data.list
          _this.totalData = r.data.pagination
          _this.pagination.count = r.data.pagination.item_total
        }
        _this.loading = false
      })
    },
    // 从子元素获取type值
    changeType(val) {
      this.type = val
      this.pageIndex = 1
      this.getList()
    },
    // 获取详情
    getDetail() {
      let _this = this
      _this.$axios.get(_this.$api.closeOrder.get_detail, {
        params: {
          id: _this.$route.query.id
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          switch (r.data.info.status) {
            case 6:
              r.data.info.status_name = '待确认'
              break
            case 8:
              r.data.info.status_name = '沟通中'
              break
            case 3:
              r.data.info.status_name = '已确认'
              break
            case 4:
              r.data.info.status_name = '已收款'
              break
            default:
              r.data.info.status_name = ''
          }
          _this.info = r.data
          _this.getList()
        }
      })
    },
    changeStatus() {
      let _this = this
      if (_this.statusType === 8 && _this.reason === '') {
        _this.$message({
          message: '请写明拒绝理由',
          type: 'error'
        })
        return false
      }
      _this.$axios.post(_this.$api.closeOrder.changestatus, {
        id: _this.$route.query.id,
        status: _this.statusType,
        reason: _this.statusType === 8 ? _this.reason : undefined
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          _this.getDetail()
          _this.reason = ''
          _this.confirmTip = false
          _this.$message({
            message: r.data.msg,
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .closeDetail p{
    margin:20px 0;
  }
  .closeDetail p span{
    display: inline-block;
    width:110px;
    text-align: right;
    margin-right:5px;
    color:#48576a;
    font-weight: 600;
  }
  .closeDetail p i{
    display: inline-block;
    min-width:220px;
  }
  .refuse{
    width:80%;
    padding: 10px;
    border:1px solid #48576A;
    border-radius:7px;
    margin: 0 auto;
    resize:none;
    height:100px;
    display: block;
  }
  .model-title {
    font-size: 18px;
    color: #0D0202;
    font-weight: 600;
    line-height: 50px;
  }
</style>
